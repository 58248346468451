import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/Layout'
import SEO from '../components/seo'
import RightSide from '../components/rightside-general'

import BG from '../images/awards-bg.jpg'

class PrivacyStatement extends React.Component {
	render() {
		const siteTitle = 'Privacy Statement'
		const siteDescription = this.props.data.site.siteMetadata.description

		return (
			<Layout location={this.props.location} title={siteTitle}>
				<SEO title={siteTitle} description={siteDescription} />
				<div
					id="sliderhead"
					style={{
						background: `url(${BG}) 50% 13px fixed rgb(34, 34, 34`,
						position: 'relative',
					}}
					className="blog-header2"
				/>
				<div className="container">
					<div className="row">
						<div className="col-md-8 left-list">
							<div id="contentgen">
								<h3 className="mb-3">Privacy Statement</h3>
								<p>
									WSC Group has a commitment to respecting your privacy and
									complying with the Privacy Amendment (Private Sector) Act 2000
									(Cth) ("The Act") and the National Privacy Principles
									("NPPs").
								</p>
								<p>
									As a certified practising accounting firm, WSC Group collects
									and holds a large range of highly sensitive personal and
									commercial information. For these reasons, WSC Group places a
									high priority on the security of information held by our firm.
									We have developed this policy to inform you how we manage your
									personal information to maintain its integrity and security.
								</p>
								<p>
									<strong>Collection</strong>
								</p>
								<p>
									The type of personal information that WSC Group collects and
									holds includes, but is not limited to, name, address, contact
									details, date of birth, occupation, financial information and
									other information which assist us in conducting our business,
									providing and marketing our services and complying with our
									legal obligations.
								</p>
								<p>
									<strong>Use & Disclosure</strong>
								</p>
								<p>
									We will only use and disclose your personal information for
									the primary purpose for which it is collected, for a secondary
									purpose which is related to the primary purpose, where that
									relationship is reasonably expected, and in other
									circumstances authorised by the Act. We collect, use and
									disclose your personal information for the following purposes:
								</p>
								<p>
									<ul>
										<li>To conduct our business;</li>
										<li>To provide services and products to you;</li>
										<li>To market our services and products;</li>
										<li>To communicate with you;</li>
										<li>To purchase from you;</li>
										<li>To comply with our legal obligations; and</li>
										<li>To help us manage and improve our services.</li>
									</ul>
								</p>
								<p>
									<strong>
										We will only disclose your personal information to:
									</strong>
								</p>
								<p>
									<ul>
										<li>
											Other companies or individuals who assist us in providing
											services or who perform functions on our behalf;
										</li>
										<li>
											Anyone else to whom you authorise us to disclose it.
										</li>
									</ul>
								</p>
								<p>
									<strong>
										How will we keep your personal information secure?
									</strong>
								</p>
								<p>
									WSC Group will take all reasonable steps to protect the
									personal information it holds. Our staff is required to
									respect the confidentiality of personal information and the
									privacy of individuals. We take reasonable steps to protect
									against the loss, misuse and/or alteration of the personal
									information under our control, for example by the use of
									physical security, information destruction devices and the
									restriction of access to and the modification of electronic
									records. Where we no longer require your personal information
									for a permitted purpose under the Act, we will take reasonable
									steps to destroy or permanently de-identify the information.
								</p>
								<p>
									<strong>
										Accessing and Correcting your Personal Information
									</strong>
								</p>
								<p>
									Subject to the exceptions set out in the Act, you may gain
									access to the personal information we hold about you. Please
									contact our Office to ask for information. You will be
									required to verify your identity and specify the information
									that you require. We will not charge you to respond to such a
									request, unless we incur costs in providing the information.
									If a fee is to be charged, we will attempt to advise you of
									the likely cost in advance. We will make all efforts to ensure
									that personal information that we hold is accurate, complete
									and up-to-date. We encourage you to contact us in order to
									update any personal information we hold about you.
								</p>
								<p>
									<strong>Contacting Us</strong>
								</p>
								<p>
									If you have any questions about this Privacy Policy or privacy
									related issues, contact our Office on (02) 8525 4600. Connect
									with WSC Group
								</p>
							</div>
						</div>
						<div className="col-md-4 right-list pull-right">
							<RightSide />
						</div>
					</div>
				</div>
			</Layout>
		)
	}
}

export default PrivacyStatement

export const PrivacyStatementPageQuery = graphql`
	query PrivacyStatementPageQuery {
		site {
			siteMetadata {
				title
				author
				description
			}
		}
	}
`
